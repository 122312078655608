import { Box } from "@material-ui/core";
import FadeLoader from "react-spinners/FadeLoader";
import Logo from "./Logo";

const SplashScreen = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "background.paper",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      left: 0,
      p: 3,
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 2000,
    }}
  >
    <Logo width="30%" />
    <div style={{ marginTop: 20 }}>
      <FadeLoader
        color="#00457b"
        loading
        height={40}
        width={5}
        margin={25}
        radius={4}
      />
    </div>
  </Box>
);

export default SplashScreen;
