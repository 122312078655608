import { TOGGLE_MERGE_LIST_DIALOG, LOG_OUT } from "../types";

const INITIAL_STATE = {
  showMergeListDialog: false,
};

export default function mergeListDialog(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case TOGGLE_MERGE_LIST_DIALOG:
      return {
        ...state,
        showMergeListDialog: action.data,
      };
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
