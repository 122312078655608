import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Avatar, Box, Divider, Drawer, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CalendarIcon from "../../icons/Calendar";
import ChartPieIcon from "../../icons/ChartPie";
import ChatAltIcon from "../../icons/ChatAlt";
import UsersIcon from "../../icons/Users";
import UserIcon from "../../icons/User";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";

const sections = [
  {
    title: "Dashboard",
    items: [
      {
        title: "Analytics",
        path: "/dashboard",
        icon: <ChartPieIcon fontSize="small" />,
      },
      {
        title: "Calendar",
        path: "/dashboard/calendar",
        icon: <CalendarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "SMS",
    items: [
      {
        title: "Bulk SMS",
        path: "/dashboard/sms",
        icon: <ChatAltIcon fontSize="small" />,
        children: [
          {
            title: "SMS Campaigns",
            path: "/dashboard/sms/campaigns",
          },
          {
            title: "SMS Lists",
            path: "/dashboard/sms/lists",
          },
          {
            title: "SMS Customers",
            path: "/dashboard/sms/customers",
          },
        ],
      },
      {
        title: "Send Single SMS",
        path: "/dashboard/sms/send-single-sms",
        icon: <ChatAltIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Push Notifications",
    items: [
      {
        title: "Bulk Push",
        path: "/dashboard/push",
        icon: <ChatAltIcon fontSize="small" />,
        children: [
          {
            title: "Push Campaigns",
            path: "/dashboard/push/campaigns",
          },
          {
            title: "Push Lists",
            path: "/dashboard/push/lists",
          },
          {
            title: "Push Customers",
            path: "/dashboard/push/customers",
          },
        ],
      },
      {
        title: "Send Single Push",
        path: "/dashboard/push/send-single-push",
        icon: <ChatAltIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Admin",
    items: [
      {
        title: "Profile",
        path: "/dashboard/profile",
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: "Manage Users",
        path: "/dashboard/admin/users",
        icon: <UsersIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/profile">
              <Avatar
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              >
                <UserIcon fontSize="large" />
              </Avatar>
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {`${user.firstName} ${user.lastName}`}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
