/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import SinPinLogo from "../images/logo.png";

const Logo = (props) => {
  let width = "15%";
  let height = "auto";
  if (props.width) {
    width = props.width;
  }
  if (props.height) {
    height = props.height;
  }
  return <img src={SinPinLogo} alt="logo" style={{ width, height }} />;
};

export default Logo;
