import { USER_INFO_FETCHED, USERS_FETCHED, LOG_OUT } from "../types";

export const setUserInfo = (data) => ({
  type: USER_INFO_FETCHED,
  data,
});

export const setAllUsers = (data) => ({
  type: USERS_FETCHED,
  data,
});

export const logOut = () => ({
  type: LOG_OUT,
});
