/* eslint-disable no-use-before-define */
/* eslint-disable no-confusing-arrow */
import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import useSettings from "../hooks/useSettings";
import { setUserInfo, setAllUsers, logOut } from "../redux/actions/authActions";
import api from "../api";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { settings, saveSettings } = useSettings();

  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = sessionStorage.getItem("token");
        if (token) {
          const user = await getCurrentUser(token);
          if (!user) {
            throw new Error("No User Found");
          }
          await loadApp(user);
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const setAuthorizationHeader = (token = null) => {
    if (token) {
      axios.defaults.headers.common.authorization = token;
    } else {
      delete axios.defaults.headers.common.authorization;
    }
  };

  const loadApp = async (user) => {
    try {
      reduxDispatch(setUserInfo(user));

      // If canManageUsers, set Users
      if (user.settings && user.settings.manageUsers) {
        const users = await api.users.getUsers();
        reduxDispatch(setAllUsers(users));
      }

      // Set Theme
      if (user.settings.theme && user.settings.theme !== settings.theme) {
        saveSettings({ ...settings, theme: user.settings.theme });
      }
      return true;
    } catch (error) {
      throw new Error("Error Loading App");
    }
  };

  const getCurrentUser = async (token) => {
    try {
      sessionStorage.setItem("token", token);
      setAuthorizationHeader(token);

      const user = await api.users.fetchCurrentUser();
      return user;
    } catch (error) {
      return null;
    }
  };

  const login = async (email, password) => {
    const token = await api.auth.login({
      email,
      password,
    });

    const user = await getCurrentUser(token);
    await loadApp(user);
    navigate("/dashboard");
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    reduxDispatch(logOut());
    sessionStorage.removeItem("token");
    setAuthorizationHeader(null);
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
