/* eslint-disable camelcase */
/* eslint-disable implicit-arrow-linebreak */
import { makeRequest } from "./utils/request";
import { BASE_API_URL } from "./utils/constants";

export default {
  users: {
    fetchCurrentUser: () =>
      makeRequest("get", `${BASE_API_URL}/users/current_user`).then(
        (res) => res.user
      ),
    getUsers: () =>
      makeRequest("get", `${BASE_API_URL}/users`).then((res) => res.users),
    editUser: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/users/${id}`, data).then(
        (res) => res
      ),
    createUser: (data) =>
      makeRequest("post", `${BASE_API_URL}/users/create_user`, data).then(
        (res) => res
      ),
    deleteUser: (id) =>
      makeRequest("delete", `${BASE_API_URL}/users/${id}`).then((res) => res),
    editTheme: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/users/edit-theme/${id}`, data).then(
        (res) => res
      ),
  },
  auth: {
    login: (data) =>
      makeRequest("post", `${BASE_API_URL}/auth/login`, data).then(
        (res) => res.token
      ),
    editPassword: (data) =>
      makeRequest("put", `${BASE_API_URL}/auth/edit-password`, data).then(
        (res) => res
      ),
    sendSmsCode: (data) =>
      makeRequest("post", `${BASE_API_URL}/auth/send-sms-code`, data).then(
        (res) => res
      ),
    verifySmsCode: (data) =>
      makeRequest("post", `${BASE_API_URL}/auth/verify-code`, data).then(
        (res) => res
      ),
    resetPassword: (data) =>
      makeRequest("post", `${BASE_API_URL}/auth/reset-password`, data).then(
        (res) => res
      ),
  },
  sms: {
    sendSingleSms: (data) =>
      makeRequest("post", `${BASE_API_URL}/sms/send-sms`, data).then(
        (res) => res.message
      ),
  },
  lists: {
    getLists: (page = "1", limit = "5", search = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/lists?page=${page}&limit=${limit}&search=${search}`
      ).then((res) => res.lists),
    getListById: (id) =>
      makeRequest("get", `${BASE_API_URL}/lists/${id}`).then((res) => res),
    deleteListById: (id) =>
      makeRequest("delete", `${BASE_API_URL}/lists/${id}`).then((res) => res),
    createList: (data) =>
      makeRequest("post", `${BASE_API_URL}/lists/create-list`, data).then(
        (res) => res
      ),
    editList: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/lists/${id}`, data).then(
        (res) => res
      ),
    getTotalRecordsById: (id) =>
      makeRequest("get", `${BASE_API_URL}/lists/mobile-phone-count/${id}`).then(
        (res) => res.totalRecords
      ),
    getTotalListsCount: () =>
      makeRequest("get", `${BASE_API_URL}/lists/total-count/total`).then(
        (res) => res.listsCount
      ),
    uploadCustomerDataFile: (data) =>
      makeRequest(
        "post",
        `${BASE_API_URL}/lists/bulk-upload-customers`,
        data
      ).then((res) => res),
    mergeLists: (data) =>
      makeRequest(
        "post",
        `${BASE_API_URL}/lists/merge-numbers-from-list`,
        data
      ).then((res) => res),
  },
  mobilephone: {
    getCustomers: (page = "1", limit = "5", search = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/mobilephone?page=${page}&limit=${limit}&search=${search}`
      ).then((res) => res.phones),
    addCustomer: (data) =>
      makeRequest("post", `${BASE_API_URL}/mobilephone`, data).then(
        (res) => res
      ),
    editCustomer: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/mobilephone/${id}`, data).then(
        (res) => res
      ),
    geCustomerById: (id) =>
      makeRequest("get", `${BASE_API_URL}/mobilephone/${id}`).then(
        (res) => res.phone
      ),
    getCustomerTotalCount: () =>
      makeRequest("get", `${BASE_API_URL}/mobilephone/total-count/total`).then(
        (res) => res.customersCount
      ),
    deleteCustomerById: (id) =>
      makeRequest("delete", `${BASE_API_URL}/mobilephone/${id}`).then(
        (res) => res
      ),
    removeSubscribedListFromCustomerById: (customer_id, list_id) =>
      makeRequest(
        "delete",
        `${BASE_API_URL}/mobilephone/remove-from-list/${customer_id}/${list_id}`
      ).then((res) => res),
  },
  campaigns: {
    getCampaigns: (page = "1", limit = "5", search = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/campaigns?page=${page}&limit=${limit}&search=${search}`
      ).then((res) => res.campaigns),
    getCampaignsByDateRange: (startDate = "", endDate = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/campaigns/date-range?startDate=${startDate}&endDate=${endDate}`
      ).then((res) => res.campaigns),
    getTotalPendingCampaigns: () =>
      makeRequest("get", `${BASE_API_URL}/campaigns/pending-total-count`).then(
        (res) => res.pendingCampaignsCount
      ),
    createCampaign: (data) =>
      makeRequest("post", `${BASE_API_URL}/campaigns`, data).then((res) => res),
    editCampaign: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/campaigns/${id}`, data).then(
        (res) => res
      ),
    deleteCampaignById: (id) =>
      makeRequest("delete", `${BASE_API_URL}/campaigns/${id}`).then(
        (res) => res
      ),
  },
  push: {
    sendPushNotifications: (data) =>
      makeRequest(
        "post",
        `${BASE_API_URL}/pushnotifications/send-bulk-push-notifications`,
        data
      ).then((res) => res),
  },
  pushlists: {
    getLists: (page = "1", limit = "5", search = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/pushlists?page=${page}&limit=${limit}&search=${search}`
      ).then((res) => res.lists),
    getListById: (id) =>
      makeRequest("get", `${BASE_API_URL}/pushlists/${id}`).then((res) => res),
    deleteListById: (id) =>
      makeRequest("delete", `${BASE_API_URL}/pushlists/${id}`).then(
        (res) => res
      ),
    createList: (data) =>
      makeRequest("post", `${BASE_API_URL}/pushlists/create-list`, data).then(
        (res) => res
      ),
    editList: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/pushlists/${id}`, data).then(
        (res) => res
      ),
    getTotalListsCount: () =>
      makeRequest("get", `${BASE_API_URL}/pushlists/total-count/total`).then(
        (res) => res.listsCount
      ),
    getTotalRecordsById: (id) =>
      makeRequest("get", `${BASE_API_URL}/pushlists/token-count/${id}`).then(
        (res) => res.totalRecords
      ),
    uploadCustomerDataFile: (data) =>
      makeRequest(
        "post",
        `${BASE_API_URL}/pushlists/bulk-upload-tokens`,
        data
      ).then((res) => res),
    mergeLists: (data) =>
      makeRequest(
        "post",
        `${BASE_API_URL}/pushlists/merge-tokens-from-list`,
        data
      ).then((res) => res),
  },
  pushtokens: {
    getCustomers: (page = "1", limit = "5", search = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/pushtokens?page=${page}&limit=${limit}&search=${search}`
      ).then((res) => res.tokens),
    addCustomer: (data) =>
      makeRequest("post", `${BASE_API_URL}/pushtokens`, data).then(
        (res) => res
      ),
    editCustomer: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/pushtokens/${id}`, data).then(
        (res) => res
      ),
    geCustomerById: (id) =>
      makeRequest("get", `${BASE_API_URL}/pushtokens/${id}`).then(
        (res) => res.token
      ),
    getCustomerTotalCount: () =>
      makeRequest("get", `${BASE_API_URL}/pushtokens/total-count/total`).then(
        (res) => res.customersCount
      ),
    deleteCustomerById: (id) =>
      makeRequest("delete", `${BASE_API_URL}/pushtokens/${id}`).then(
        (res) => res
      ),
    removeSubscribedListFromCustomerById: (customer_id, list_id) =>
      makeRequest(
        "delete",
        `${BASE_API_URL}/pushtokens/remove-from-list/${customer_id}/${list_id}`
      ).then((res) => res),
  },
  pushcampaigns: {
    getCampaigns: (page = "1", limit = "5", search = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/pushcampaigns?page=${page}&limit=${limit}&search=${search}`
      ).then((res) => res.campaigns),
    getCampaignsByDateRange: (startDate = "", endDate = "") =>
      makeRequest(
        "get",
        `${BASE_API_URL}/pushcampaigns/date-range?startDate=${startDate}&endDate=${endDate}`
      ).then((res) => res.campaigns),
    getTotalPendingCampaigns: () =>
      makeRequest("get", `${BASE_API_URL}/pushcampaigns/pending-total-count`).then(
        (res) => res.pendingCampaignsCount
      ),
    createCampaign: (data) =>
      makeRequest("post", `${BASE_API_URL}/pushcampaigns`, data).then((res) => res),
    editCampaign: (id, data) =>
      makeRequest("put", `${BASE_API_URL}/pushcampaigns/${id}`, data).then(
        (res) => res
      ),
    deleteCampaignById: (id) =>
      makeRequest("delete", `${BASE_API_URL}/pushcampaigns/${id}`).then(
        (res) => res
      ),
  },
};
