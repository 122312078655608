/* eslint-disable implicit-arrow-linebreak */
import { Suspense, lazy } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const ForgotPasswordPage = Loadable(
  lazy(() => import("./pages/authentication/ForgotPasswordPage"))
);

// Dashboard pages

const Profile = Loadable(lazy(() => import("./pages/dashboard/user/Profile")));
const Analytics = Loadable(lazy(() => import("./pages/dashboard/Analytics")));
const Calendar = Loadable(lazy(() => import("./pages/dashboard/Calendar")));
const SingleSms = Loadable(
  lazy(() => import("./pages/dashboard/sms/SingleSms"))
);
const Lists = Loadable(lazy(() => import("./pages/dashboard/sms/Lists")));
const ListDetails = Loadable(
  lazy(() => import("./pages/dashboard/sms/ListDetails"))
);
const AddList = Loadable(lazy(() => import("./pages/dashboard/sms/AddList")));
const EditList = Loadable(lazy(() => import("./pages/dashboard/sms/EditList")));
const Campaigns = Loadable(
  lazy(() => import("./pages/dashboard/sms/Campaigns"))
);
const Customers = Loadable(
  lazy(() => import("./pages/dashboard/sms/Customers"))
);
const AddCustomer = Loadable(
  lazy(() => import("./pages/dashboard/sms/AddCustomer"))
);
const CustomerDetails = Loadable(
  lazy(() => import("./pages/dashboard/sms/CustomerDetails"))
);
const ManageUsers = Loadable(
  lazy(() => import("./pages/dashboard/user/ManageUsers"))
);
const EditUser = Loadable(
  lazy(() => import("./pages/dashboard/user/EditUser"))
);
const AddUser = Loadable(lazy(() => import("./pages/dashboard/user/AddUser")));

const SinglePush = Loadable(
  lazy(() => import("./pages/dashboard/push/SinglePush"))
);

const PushLists = Loadable(
  lazy(() => import("./pages/dashboard/push/PushList"))
);
const AddPushList = Loadable(
  lazy(() => import("./pages/dashboard/push/AddPushList"))
);
const EditPushList = Loadable(
  lazy(() => import("./pages/dashboard/push/EditPushList"))
);
const PushListDetails = Loadable(
  lazy(() => import("./pages/dashboard/push/PushListDetails"))
);
const PushCustomers = Loadable(
  lazy(() => import("./pages/dashboard/push/PushCustomers"))
);
const AddPushCustomer = Loadable(
  lazy(() => import("./pages/dashboard/push/AddPushCustomer"))
);
const PushCustomerDetails = Loadable(
  lazy(() => import("./pages/dashboard/push/PushCustomerDetails"))
);
const PushCampaigns = Loadable(
  lazy(() => import("./pages/dashboard/push/PushCampaigns"))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const routes = [
  {
    path: "authentication",
    element: <MainLayout />,
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "reset-password",
        element: <ForgotPasswordPage />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Analytics />,
      },
      {
        path: "calendar",
        element: <Calendar />,
      },
      {
        path: "admin",
        children: [
          {
            path: "/users",
            element: <ManageUsers />,
          },
          {
            path: "/users/edit-user/:id",
            element: <EditUser />,
          },
          {
            path: "/users/add-user",
            element: <AddUser />,
          },
        ],
      },
      {
        path: "sms",
        children: [
          {
            path: "/send-single-sms",
            element: <SingleSms />,
          },
          {
            path: "/lists",
            element: <Lists />,
          },
          {
            path: "/lists/add-list",
            element: <AddList />,
          },
          {
            path: "/lists/edit-list/:listId",
            element: <EditList />,
          },
          {
            path: "/lists/list/:id",
            element: <ListDetails />,
          },
          {
            path: "/campaigns",
            element: <Campaigns />,
          },
          {
            path: "/customers",
            element: <Customers />,
          },
          {
            path: "/customers/customer/:id",
            element: <CustomerDetails />,
          },
          {
            path: "/customers/add-customer",
            element: <AddCustomer />,
          },
        ],
      },
      {
        path: "push",
        children: [
          {
            path: "/send-single-push",
            element: <SinglePush />,
          },
          {
            path: "/lists",
            element: <PushLists />,
          },
          {
            path: "/lists/add-list",
            element: <AddPushList />,
          },
          {
            path: "/lists/edit-list/:listId",
            element: <EditPushList />,
          },
          {
            path: "/lists/list/:id",
            element: <PushListDetails />,
          },
          {
            path: "/campaigns",
            element: <PushCampaigns />,
          },
          {
            path: "/customers",
            element: <PushCustomers />,
          },
          {
            path: "/customers/customer/:id",
            element: <PushCustomerDetails />,
          },
          {
            path: "/customers/add-customer",
            element: <AddPushCustomer />,
          },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
