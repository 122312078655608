import { combineReducers } from "redux";
import user from "./userReducer";
import users from "./usersReducer";
import applicationState from "./applicationReducer";
import campaignDialog from "./campaignDialogReducer";
import mergeListDialog from "./mergeListDialogReducer";
import pushCampaignDialog from "./pushCampaignDialogReducer";

export default combineReducers({
  user,
  users,
  applicationState,
  campaignDialog,
  pushCampaignDialog,
  mergeListDialog,
});
