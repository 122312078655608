const DEV_BASE_URL = "http://localhost:8085/api";
const PRODUCTION_BASE_URL = "https://sinpin-bulk-sms-api.herokuapp.com/api";

const getBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return `${DEV_BASE_URL}`;
  }
  return PRODUCTION_BASE_URL;
};

export const BASE_API_URL = getBaseUrl();
