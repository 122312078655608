import {
  TOGGLE_CAMPAIGN_DIALOG,
  SET_SELECTED_LIST,
  SET_SELECTED_CAMPAIGN,
  SET_CAMPAIGN_FORM_TYPE,
  SET_DATE_FROM_CALENDAR,
  SET_CAMPAIGN_FORM_PAGE_TO_REFRESH,
  CLEAR_CAMPAIGN_DIALOG,
  LOG_OUT,
} from "../types";

const INITIAL_STATE = {
  showCampaignDialog: false,
  selectedList: {},
  selectedCampaign: {},
  formType: "",
  dateFromCalendar: "",
  pageToRefresh: "",
};

export default function campaignDialog(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case TOGGLE_CAMPAIGN_DIALOG:
      return {
        ...state,
        showCampaignDialog: action.data,
      };
    case SET_SELECTED_LIST:
      return {
        ...state,
        selectedList: action.data,
      };
    case SET_SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.data,
      };
    case SET_CAMPAIGN_FORM_TYPE:
      return {
        ...state,
        formType: action.data,
      };
    case SET_DATE_FROM_CALENDAR:
      return {
        ...state,
        dateFromCalendar: action.data,
      };
    case SET_CAMPAIGN_FORM_PAGE_TO_REFRESH:
      return {
        ...state,
        pageToRefresh: action.data,
      };
    case CLEAR_CAMPAIGN_DIALOG:
      return INITIAL_STATE;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
