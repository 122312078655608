/* eslint-disable operator-linebreak */
// Application
export const LOG_OUT = "LOG_OUT";
export const SET_APP_LOADING = "SET_APP_LOADING";

// Users
export const USER_INFO_FETCHED = "USER_INFO_FETCHED";
export const USERS_FETCHED = "USERS_FETCHED";

// Campaigns
export const TOGGLE_CAMPAIGN_DIALOG = "TOGGLE_CAMPAIGN_DIALOG";
export const SET_SELECTED_LIST = "SET_SELECTED_LIST";
export const SET_SELECTED_CAMPAIGN = "SET_SELECTED_CAMPAIGN";
export const CLEAR_CAMPAIGN_DIALOG = "CLEAR_CAMPAIGN_DIALOG";
export const SET_CAMPAIGN_FORM_TYPE = "SET_CAMPAIGN_FORM_TYPE";
export const SET_DATE_FROM_CALENDAR = "SET_DATE_FROM_CALENDAR";
export const SET_CAMPAIGN_FORM_PAGE_TO_REFRESH =
  "SET_CAMPAIGN_FORM_PAGE_TO_REFRESH";

// Push Campaigns
export const TOGGLE_PUSH_CAMPAIGN_DIALOG = "TOGGLE_PUSH_CAMPAIGN_DIALOG";
export const SET_SELECTED_PUSH_LIST = "SET_SELECTED_PUSH_LIST";
export const SET_SELECTED_PUSH_CAMPAIGN = "SET_SELECTED_PUSH_CAMPAIGN";
export const CLEAR_PUSH_CAMPAIGN_DIALOG = "CLEAR_PUSH_CAMPAIGN_DIALOG";
export const SET_PUSH_CAMPAIGN_FORM_TYPE = "SET_PUSH_CAMPAIGN_FORM_TYPE";
export const SET_PUSH_DATE_FROM_CALENDAR = "SET_PUSH_DATE_FROM_CALENDAR";
export const SET_PUSH_CAMPAIGN_FORM_PAGE_TO_REFRESH =
  "SET_PUSH_CAMPAIGN_FORM_PAGE_TO_REFRESH";

export const TOGGLE_MERGE_LIST_DIALOG = "TOGGLE_MERGE_LIST_DIALOG";
