/* eslint-disable consistent-return */
import axios from "axios";

const removeToken = () => {
  sessionStorage.removeItem("token");
};

const getToken = () => {
  const token = sessionStorage.getItem("token");

  if (token) {
    return token;
  }

  return null;
};

const parseErrors = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 401:
        removeToken();
        window.location = "/";
        break;
      default:
        throw err.response.data;
    }
  } else if (err.request) {
    throw err.request;
  } else {
    throw err;
  }
};

const createHeaders = (additionalHeaders = {}) => {
  const headers = {
    "Content-Type": "application/json",
    ...additionalHeaders,
  };

  const token = getToken();
  if (token) {
    axios.defaults.headers.common.authorization = token;
  }

  return headers;
};

export const makeRequest = async (
  method,
  url,
  data = {},
  additionalHeaders = {}
) => {
  const headers = createHeaders(additionalHeaders);

  try {
    const res = await axios({
      method,
      url,
      data,
      headers,
    });
    return res.data;
  } catch (err) {
    parseErrors(err);
  }
};
