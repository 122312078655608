import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Divider, Toolbar } from "@material-ui/core";
import Logo from "./Logo";

const MainNavbar = () => {
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        color: "text.secondary",
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: "inline",
                xs: "none",
              },
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MainNavbar;
