import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import ReactDOM from "react-dom";
import axios from "axios";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { SettingsProvider } from "./contexts/SettingsContext";

const token = sessionStorage.getItem("token");

// This code initializes axios for common headers
function setAuthorizationHeader(authToken = null) {
  if (authToken) {
    axios.defaults.headers.common.authorization = authToken;
  } else {
    delete axios.defaults.headers.common.authorization;
  }
}
async function initAxios() {
  axios.defaults.headers.common.accept = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";
  if (token) {
    setAuthorizationHeader(token);
  }
}

initAxios();

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);
